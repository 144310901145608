<template>
  <div>
    <v-card elevation="12">
      <v-card-text>
        <h1>Tesztverzió, fejlesztés alatt</h1>
        <p>Fejlesztő: Vörös László</p>
        <p>Érdeklődni lehet: <span style="direction: rtl; unicode-bidi: bidi-override;">uh.olpanesim@olzsalsorov</span></p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'tipsAndNews',
  data () {
    return {

    }
  },
}
</script>
