var roles = {};

roles.unauthenticated=-1
roles.believer=0;
roles.signer=1;
roles.catechist=2;
roles.parishOfficer=3;
roles.chaplain=4;
roles.parishPriest=5;
roles.deanOfficer=6;
roles.dean=7;
roles.bishopOfficer=8;
roles.bishop=9;
roles.archbishopOfficer = 10;
roles.archbishop=11;
roles.popeOfficer = 12;
roles.pope = 13;
roles.admin = 14;
export default roles;

